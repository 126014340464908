import { contractorsActions } from "_store/contractors.slice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ContractorMainDetails } from "./Extensions/ContractorDetails/ContractorMainDetails";
import AddressesDetails from "./Extensions/ContractorDetails/AddressesDetails";
import ContactPersonListDetails from "./Extensions/ContractorDetails/ContactPersonsDetails";
import { IntermediaryListDetails } from "./Extensions/ContractorDetails/IntermediaryListDetails";
import { DomainButton } from "_components/Abstractions/DomainButton";
import { ChangeContractorState } from "./ChangeContractorState";
import { ContractorBdoDetails } from "./Extensions/ContractorDetails/ContractorBdoDetails";
import { BackButton } from "../../_components/Abstractions/BackButton";
import { useToast } from "../../_helpers/notifications/useToasts";
import { ContractorPricingOffcanvas } from "./Extensions/CreateContractor/ContractorRatePlans/ContractorRatePlanForm";
import { ContractorRatePlansDetails } from "./Extensions/ContractorDetails/ContractorRatePlansDetails";

export function ContractorDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showErrorMessage } = useToast();
  const { contractorData } = useSelector(x => x.contractors);
  const Actions = (
    <div className="mb-2">
      <BackButton
        className="me-1"
        link="/configuration"
      >Powrót do listy klientów
      </BackButton>
      {contractorData && <>
        <DomainButton
          className="me-1"
          disabled={contractorData?.isLoading}
          onClick={() =>
            navigate(`/contractors/${id}/update`, {
              state: { contractor: contractorData }
            })
          }
        >
          Edytuj klienta
        </DomainButton>
        <ContractorPricingOffcanvas contractorId={id} contractorTypes={contractorData?.contractorTypes} intermediaries={contractorData?.intermediaries} addresses={contractorData?.addresses} btnTitle="Dodaj cennik"/>
        <ChangeContractorState
          contractor={contractorData}
          detailsUpdate
        />
      </>}
    </div>
  );

  useEffect(() => {
    const getContractorData = async(id) => {
      const response = await dispatch(contractorsActions.getById({ id }));
      await dispatch(contractorsActions.getRatePlans({ id }));
      const { payload } = response;

      if (!payload) {
        showErrorMessage("Nie udało się pobrać danych kontrahenta, spróbuj ponownie...");
      }
    };

    getContractorData(id);
  }, []);

  return (
    <div className="container">
      {contractorData?.isLoading && <div className="spinner-border"></div>}
      {!contractorData?.isLoading && Actions}
      {contractorData && <>
        <ContractorMainDetails contractorData={contractorData}/>
        <IntermediaryListDetails intermediaries={contractorData?.intermediaries}/>
        <ContractorBdoDetails contractorTypes={contractorData?.contractorTypes} isBdoLinked={contractorData?.isBdoLinked} bdoDetails={contractorData?.bdoDetails}/>
        <AddressesDetails addresses={contractorData?.addresses}/>
        <ContractorRatePlansDetails/>
        <ContactPersonListDetails contactPersons={contractorData?.contactPersons}/>
      </>}
    </div>
  );
}
