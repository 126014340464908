import { Accordion, Card, Table } from "react-bootstrap";
import React, { useRef } from "react";
import { BadgeDomain } from "../../../../_components/Abstractions/BadgeDomain";
import { useSelector } from "react-redux";
import { TransactionTypeBadge } from "../../../../_components/OrderViews/Components/TransactionTypeBadge";
import { DomainButton } from "../../../../_components/Abstractions/DomainButton";
import { ContractorPricingOffcanvas } from "../CreateContractor/ContractorRatePlans/ContractorRatePlanForm";
import { LoadingSpinner } from "../../../../_components/Abstractions/LoadingSpinner";
import { ContractorRatePlansHistory } from "./ContractorRatePlansHistory";
import { useReactToPrint } from "react-to-print";
import { Printer } from "react-bootstrap-icons";
import s from "./style.module.css"
import { stringToDateView } from "../../../../domain/Configuration";
import { StatusBadge } from "../../../../_components/Abstractions/StatusBadge";
import { ContractorRelatedRatePlansDetails } from "./ContractorRelatedRatePlanDetails";

export function ContractorRatePlansDetails() {
  const { contractorData: { id, name, ratePlans, intermediaries, contractorTypes, addresses } } = useSelector(x => x.contractors);
  const isLoading = !ratePlans || ratePlans?.loading;
  if(!ratePlans || ratePlans?.length === 0) {
    return;
  }

  return (
    <Card className={s.sectionCard}>
      <Card.Header as="h4">Cenniki</Card.Header>
      <Card.Body className="upper">
        <LoadingSpinner isLoading={ratePlans?.loading} as="div"/>
        <ContractorRatePlansView
          isLoading={isLoading}
          ratePlans={ratePlans}
          contractorId={id}
          contractorName={name}
          contractorTypes={contractorTypes}
          addresses={addresses}
          intermediaries={intermediaries}
          isRelatedView={false}/>
      </Card.Body>
    </Card>
  );
}

export const ContractorRatePlansView = ({ isLoading, ratePlans, contractorId, contractorName, contractorTypes, intermediaries, addresses, isRelatedView }) => {
  if(isLoading) {
    return;
  }

  return <Accordion className={s.group} alwaysOpen>
    {ratePlans?.map((ratePlan, idx) =>
      (<ContractorRatePlanDetails
          key={idx}
          eventKey={idx}
          contractorId={contractorId}
          contractorName={contractorName}
          ratePlan={ratePlan}
          contractorTypes={contractorTypes}
          addresses={addresses}
          intermediaries={intermediaries}
          isRelatedView={isRelatedView}/>
      ))}
  </Accordion>
}

export const ContractorRatePlanDetails = ({ eventKey, ratePlan, contractorId, contractorName, contractorTypes, intermediaries, addresses, isRelatedView }) => {
  const intermediariesDictionary = intermediaries ? Object.fromEntries(intermediaries?.map((x) => [x.intermediaryId, x.name])) : [];
  const addressDictionary = addresses ? Object.fromEntries(addresses?.map((x) => [x.id, x.name])) : [];
  const formRef = useRef();
  const handlePrint = useReactToPrint({ content: () => formRef.current });
  // eventKey={idx}
      return <>
    <Accordion.Item eventKey={eventKey} key={ratePlan.id}>
      <Accordion.Header>
        <Card.Subtitle>
          Cennik {ratePlan.intermediaryId ?
          <>pośrednika <BadgeDomain bg="success">{intermediariesDictionary[ratePlan.intermediaryId]}</BadgeDomain></> : "klienta"}
          {ratePlan.addressId && <> ({addressDictionary[ratePlan.addressId]})</>}
          {": "} [od {stringToDateView(ratePlan.dateFrom)} do {stringToDateView(ratePlan.dateTo)}]
          {" "}<TransactionTypeBadge transactionType={ratePlan.type}/>
          {" "}<StatusBadge isActive={ratePlan.isActive}/>
        </Card.Subtitle>
      </Accordion.Header>
      <Accordion.Body>
        <div className={`d-flex mb-2 ${s.printHide}`}>
          <div className="d-flex gap-1">
            {!isRelatedView &&
              <>
                <ContractorPricingOffcanvas
                  ratePlanId={ratePlan?.id}
                  contractorId={contractorId}
                  contractorTypes={contractorTypes}
                  intermediaries={intermediaries}
                  addresses={addresses}
                  btnTitle="Edytuj"
                  size="smaller"
                  btnSize="sm"
                  ratePlan={ratePlan}
                />
                <ContractorPricingOffcanvas
                  ratePlanId={null}
                  contractorId={contractorId}
                  contractorTypes={contractorTypes}
                  intermediaries={intermediaries}
                  addresses={addresses}
                  btnTitle="Kopiuj cennik"
                  size="smaller"
                  btnSize="sm"
                  ratePlan={ratePlan}
                  isClone
                />
            <ContractorRelatedRatePlansDetails size="smaller" ratePlan={ratePlan} contractorId={contractorId}
                                               contractorTypes={contractorTypes}
                                               intermediaries={intermediaries}
                                               addresses={addresses}/>
            <ContractorRatePlansHistory size="smaller" ratePlan={ratePlan} />
              </>}
            <DomainButton size="smaller" variant="success" onClick={handlePrint}>Drukuj <Printer/></DomainButton>
          </div>
        </div>
        <div ref={formRef} className={s.pageBreak}>
          <Card.Subtitle className={`d-none ${s.printShow}`}>
            Cennik {ratePlan.intermediaryId &&
            <>pośrednika <BadgeDomain bg="success">{intermediariesDictionary[ratePlan.intermediaryId]}</BadgeDomain> dla</>} klienta {contractorName}
            {" "}<TransactionTypeBadge transactionType={ratePlan.type}/>
          </Card.Subtitle>
          <div className="d-flex gap-4">
            <PricingTable
              name="Towary"
              showAdvancedPricing
              items={ratePlan.wastes?.map(wasteRatePlan => <tr key={wasteRatePlan.wasteId}>
                <td>
                  <BadgeDomain bg={wasteRatePlan.isDefined ? "primary" : "danger"} size="x-small">{wasteRatePlan.waste?.displayDescription}</BadgeDomain>
                </td>
                <td>{!wasteRatePlan.isAdvancedPricing ? wasteRatePlan.price?.toFixed(2) : "-"} zł</td>
                <td>{wasteRatePlan.isAdvancedPricing ? wasteRatePlan.wasteAdvancedPricing?.["Baled"]?.toFixed(2) : "-"} zł</td>
                <td>{wasteRatePlan.isAdvancedPricing ? wasteRatePlan.wasteAdvancedPricing?.["Loose"]?.toFixed(2) : "-"} zł</td>
              </tr>)}/>
            <div className="vr"></div>
            <PricingTable
              name="Usługi"
              items={ratePlan.services?.map(serviceRatePlan => <tr key={serviceRatePlan.serviceId}>
                <td>
                  <BadgeDomain size="x-small">{serviceRatePlan.service?.name}</BadgeDomain>
                </td>
                <td>{serviceRatePlan.price?.toFixed(2)} zł</td>
              </tr>)}/>
          </div>
        </div>
      </Accordion.Body>
      <Card/>
    </Accordion.Item>
  </>
}

const PricingTable = ({ name, items, showAdvancedPricing }) => {
  return <div className="w-100">
    {items?.length === 0 && <>{name} - Brak</>}
    {items?.length > 0 && <Table className="mb-3">
      <thead>
      <tr>
        <td style={{ width: "50%" }}>{name}</td>
        <td style={{ width: "15%" }}>Cena</td>
        {showAdvancedPricing && <>
        <td style={{ width: "15%" }}>Cena - bel.</td>
        <td style={{ width: "15%" }}>Cena - luz</td>
        </>}
      </tr>
      </thead>
      <tbody>
      {items}
      </tbody>
    </Table>}
  </div>;
};