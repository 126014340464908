import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { contractorsActions } from "../../../../../_store/contractors.slice";
import { ContractorRatePlanForm } from "./ContractorRatePlanForm";
import { stringToDateForm } from "../../../../../domain/Configuration";

export function CreateContractorRatePlan({ ratePlanId, contractorId, contractorTypes, intermediaries, addresses, ratePlan, setShowOffCanvas, isClone }) {
  const dispatch = useDispatch();
  const { contractorWastes } = useSelector((x) => x.contractors);
  const intermediariesDictionary = intermediaries
    ? Object.fromEntries(intermediaries?.map((x) => [x.intermediaryId, x.name]))
    : [];
  const addressesDictionary = addresses
    ? Object.fromEntries(addresses?.map((x) => [x.id, x.name]))
    : [];

  useEffect(() => {
    if (!contractorId || ratePlanId) {
      return;
    }

    const getWastes = async() => {
      await dispatch(contractorsActions.getWastes({ id: contractorId }));
    };

    getWastes();
  }, [contractorId]);

  const isSender = contractorTypes.includes("Sender");
  const isReceiver = contractorTypes.includes("Receiver");
  const isTypeEditable = isSender && isReceiver && !isClone;
  const dateFromForClone = isClone && ratePlan?.dateTo ? new Date(ratePlan.dateTo) : null;
  dateFromForClone?.setDate(new Date(ratePlan.dateTo).getDate() + 1)

  const type = isSender ? "Purchase" : isReceiver ? "Sale" : undefined;
  const defaultValues = {
    contractorTypes,
    isTypeEditable,
    type: !isTypeEditable ? type : undefined,
    isIntermediaryPricing: !!ratePlan?.intermediaryId,
    ratePlanId,
    ...ratePlan,
    dateFrom: ratePlan?.dateFrom ?
      !isClone || !dateFromForClone ? stringToDateForm(ratePlan.dateFrom) : stringToDateForm(dateFromForClone)
      : null,
    dateTo: ratePlan?.dateTo && !isClone ? stringToDateForm(ratePlan.dateTo) : null,
    wastes: !ratePlanId && !isClone ?
      !contractorWastes?.loading && contractorWastes?.map(x => ({ waste: x })) :
      ratePlan?.wastes.map(x => ({
        ...x,
        price: x.price?.toFixed(2),
        priceBaled: x.wasteAdvancedPricing?.["Baled"],
        priceLoose: x.wasteAdvancedPricing?.["Loose"]
      })),
    services: ratePlan?.services.map(x => ({ ...x, price: x.price?.toFixed(2) })),
    intermediaries: intermediariesDictionary,
    addresses: addressesDictionary,
    isClone,
    originalRatePlanId: isClone ? ratePlan?.originalRatePlanId ?? ratePlan?.id : null,
  };
  if (!contractorId || !defaultValues.wastes || defaultValues.wastes?.length === 0 || defaultValues.wastes?.loading) {
    return <p>Aby stworzyć cennik, należy najpierw zdefiniować towary w załadunku / kontenerach</p>
  }

  // if(isClone && !ratePlan?.dateTo) {
  //   return <p>Aby skopiować cennik, należy najpierw określić datę końcową aktywnego cennika </p>
  // }

  return <ContractorRatePlanForm contractorId={contractorId} defaultValues={defaultValues} setShowOffCanvas={setShowOffCanvas}/>;
}