import { OffcanvasDomain } from "../../../../../_components/Abstractions/OffcanvasDomain";
import { CreateContractorRatePlan } from "./CreateContractorRatePlan";
import { FormDomain } from "../../../../../_components/Forms/FormDomain";
import { WastesRatePlanForm } from "./WastesRatePlanForm";
import { ServicesRatePlanForm } from "./ServicesRatePlanForm";
import * as Yup from "yup";
import { DefinitionRatePlanForm } from "./DefinitionRatePlanForm";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { contractorsActions } from "../../../../../_store/contractors.slice";
import moment from "moment";

export function ContractorPricingOffcanvas({ ratePlanId, contractorId, contractorTypes, intermediaries, addresses, ratePlan, size, btnSize = "md", btnTitle, isClone = false }) {
  const title = "Cennik";
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const form = <CreateContractorRatePlan isClone={isClone} setShowOffCanvas={setShowOffCanvas} ratePlanId={ratePlanId} contractorId={contractorId} ratePlan={ratePlan} contractorTypes={contractorTypes} intermediaries={intermediaries} addresses={addresses}/>;
  return (
    <>
      <OffcanvasDomain
        setShowOffCanvas={setShowOffCanvas}
        showOffCanvas={showOffCanvas}
        btnVariant="outline-primary"
        size={size}
        btnSize={btnSize}
        buttonContent={btnTitle}
        title={title}
        // disabledBtnTrigger={isClone && !ratePlan?.dateTo}
        body={form}/>
    </>);
}

export function ContractorRatePlanForm({ contractorId, defaultValues, setShowOffCanvas }) {
  const dispatch = useDispatch();
  const { ratePlanId } = defaultValues;
  const formControls = <div className="d-flex flex-column gap-2">
    <DefinitionRatePlanForm/>
    <WastesRatePlanForm/>
    <ServicesRatePlanForm/>
  </div>;

  const priceValidationSchema = Yup.number()
  .min(0.01, "Cena musi być większa od zera")
  .typeError("Cena jest wymagana")
  .required("Cena jest wymagana");

  const wasteValidationSchema = Yup.object().shape({
    waste: Yup.object().required("Towar jest wymagany").typeError("Towar jest wymagany"),
    price: Yup.number().when("isAdvancedPricing", { is: false, then: priceValidationSchema }),
    priceBaled: Yup.number().when("isAdvancedPricing", { is: true, then: priceValidationSchema }),
    priceLoose: Yup.number().when("isAdvancedPricing", { is: true, then: priceValidationSchema }),
  });

  const serviceValidationSchema = Yup.object().shape({
    service: Yup.object().required("Usługa jest wymagana").typeError("Usługa jest wymagana"),
    price: priceValidationSchema
  });

  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Typ cennika jest wymagany").typeError("Typ cennika jest wymagany"),
    intermediaryId: Yup.string().nullable().when("isIntermediaryPricing", {
      is: true,
      then: Yup.string().required("Pośrednik jest wymagany")
    }),
    dateFrom: Yup.date().typeError("Data początkowa od jest wymagana"),
    dateTo: Yup.string().nullable().test("dateFrom-vs-dateTo", "Data końcowa nie może być wcześniejsza od daty początkowej", function(value) {
      const { dateFrom: dateFromField } = this.parent;
      if (!value) {
        return true;
      }
      const dateFrom = moment(dateFromField, "YYYY-MM-DD").startOf("day");
      const dateTo = moment(value, "YYYY-MM-DD").startOf("day");
      return dateTo.isSameOrAfter(dateFrom);
    }),
    wastes: Yup.array().of(wasteValidationSchema).test("areWastesUnique", "Towary nie mogą się powtarzać", function(value) {
      const ids = value?.map(x => x.waste?.wasteId);
      if (!ids || ids?.length === 0) {
        return true;
      }

      return new Set(ids).size === value?.length;
    }),
    services: Yup.array().of(serviceValidationSchema).test("areServicesUnique", "Usługi nie mogą się powtarzać", function(value) {
      const ids = value?.map(x => x.service?.serviceId);
      if (!ids || ids?.length === 0) {
        return true;
      }

      return new Set(ids).size === value?.length;
    })
  });

  const onSubmit = async(data) => {
    const wastes = data.wastes?.map(x => {
      const isAdvancedPricing = x.isAdvancedPricing;
      const wasteAdvancedPricing = [];
      if (isAdvancedPricing) {
        wasteAdvancedPricing.push({ wasteFormType: "Baled", price: x.priceBaled });
        wasteAdvancedPricing.push({ wasteFormType: "Loose", price: x.priceLoose });
      }

      return ({
        id: x.id,
        entityId: x.waste?.wasteId,
        price: !isAdvancedPricing ? x.price : 0,
        pricingType: "Waste",
        isAdvancedPricing: isAdvancedPricing,
        wasteAdvancedPricing: isAdvancedPricing ? wasteAdvancedPricing : null
      });
    });
    const services = data.services?.map(x => ({ id: x.id, entityId: x.service?.serviceId, price: x.price, pricingType: "Service" }));
    const items = [ ...wastes, ...services ];
    const payload = {
      id: data.ratePlanId,
      contractorId: contractorId,
      addressId: data.addressId !== "" ? data.addressId : null,
      dateFrom: data.dateFrom !== "" ? data.dateFrom : null,
      dateTo: data.dateTo !== "" ? data.dateTo : null,
      intermediaryId: data.isIntermediaryPricing ? data.intermediaryId : null,
      type: data.type,
      originalRatePlanId: data.originalRatePlanId,
      items
    }

    const response = await dispatch(contractorsActions.upsertRatePlan({ payload }));
    if (!response?.error) {
      dispatch(contractorsActions.getRatePlans({ id: contractorId }));
      setShowOffCanvas(false);
      // const pageNumber = containersPaging?.pageNumber ?? 1;
    }

    return response;
  };

  return (
    <>
      <FormDomain
        formControls={formControls}
        validationSchema={validationSchema}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        // buttonText={buttonText}
        successMessage={!ratePlanId ? "Dodano cennik" : "Zaktualizowano cennik"}
        title="Cennik"
        btnSize="small"
        addSubmitFooter={false}
      />
    </>
  );
}