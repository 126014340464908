import { useFieldArray, useFormContext } from "react-hook-form";
import { NumericControl } from "../../../../../_components/Forms/NumericControl";
import { CardView } from "../../../../../_components/Abstractions/CardView";
import { HorizontalView } from "../../../../../_components/Abstractions/HorizontalView";
import { WastesSelect } from "../WastesSelect";
import { DomainButton } from "../../../../../_components/Abstractions/DomainButton";
import { DomainSwitch } from "../../../../../_components/Abstractions/DomainSwitch";

export function WastesRatePlanForm() {
  const { fields, append, remove } = useFieldArray({ name: "wastes" });
  const { watch, getFieldState } = useFormContext();
  const fieldState = getFieldState("wastes");
  const type = watch("type");
  const isPurchase = type === "Purchase";
  // const GetFieldName = (fieldName, index) => `wastes[${index}].${fieldName}`;
  const addItem = () => {
    append({
      waste: null,
      price: null
    });
  };

  return <>
    <CardView headerText="Towary" headerTypo="h5">
      <div className="domain-control error mb-2">{fieldState?.error?.message}</div>
      {fields?.map((waste, index) => (
        <WasteRatePlanForm key={waste.id} index={index} isPurchase={isPurchase} onRemove={remove}/>
      ))}
      {!isPurchase && <DomainButton onClick={addItem}>Dodaj towar</DomainButton>}
    </CardView>
  </>;
}

const WasteRatePlanForm = ({ index, onRemove, isPurchase }) => {
  const GetFieldName = (fieldName, index) => `wastes[${index}].${fieldName}`;
  const { watch } = useFormContext();
  const isAdvancedPricing = watch(GetFieldName("isAdvancedPricing", index))

  return <>
    <HorizontalView columnsSize={[7, 3, 2]}>
      <WastesSelect name={GetFieldName("waste", index)} label="Towar" skipInitLoading readonly={isPurchase}/>
      {/*<FormControl name={GetFieldName("displayDescription", index)} label="Towar" readonly/>*/}
      {!isAdvancedPricing && <NumericControl name={GetFieldName("price", index)} label="Cena [zł]"/>}
      {!isPurchase && <DomainButton size="large" onClick={() => onRemove(index)} className="mt-2">Usuń</DomainButton>}
    </HorizontalView>
    <HorizontalView columnsSize={[3, 3, 3]}>
      {isAdvancedPricing && <NumericControl name={GetFieldName("priceBaled", index)} label="Cena belowany [zł]"/>}
      {isAdvancedPricing && <NumericControl name={GetFieldName("priceLoose", index)} label="Cena luz [zł]"/>}
      <DomainSwitch name={GetFieldName("isAdvancedPricing", index)} label="Zaawansowany cennik"></DomainSwitch>
    </HorizontalView>
  </>
}
