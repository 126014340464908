import { BadgeDomain } from "../../../../_components/Abstractions/BadgeDomain";
import { OffcanvasDomain } from "../../../../_components/Abstractions/OffcanvasDomain";
import React from "react";
import { ContractorRatePlansView } from "./ContractorRatePlansDetails";

export function ContractorRelatedRatePlansDetails({ ratePlan, contractorId, contractorName, contractorTypes, addresses, intermediaries }) {
  const { relatedRatePlans } = ratePlan;
  const title = "Powiązane cenniki";
  const btnTitle = <>Powiązane cenniki <BadgeDomain bg="success">{relatedRatePlans?.length ?? 0}</BadgeDomain></>;
  const body = <ContractorRatePlansView
    isLoading={false}
    contractorId={contractorId}
    contractorName={contractorName}
    contractorTypes={contractorTypes}
    addresses={addresses}
    intermediaries={intermediaries}
    ratePlans={relatedRatePlans}
    isRelatedView={true}/>

  return (
    <>
      <OffcanvasDomain
        btnVariant="outline-primary"
        size="smaller"
        btnSize="sm"
        buttonContent={btnTitle}
        title={title}
        body={body}
      />
    </>);
}