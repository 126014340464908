import { Offcanvas } from "react-bootstrap";
import { useEffect, useState } from "react";
import { DomainButton } from "./DomainButton";

export function OffcanvasDomain(props) {
  const [show, setShow] = useState(false);

  const handleClose = (e) => {
    setShowOffCanvas?.(false);
    setShow(false);
    e?.stopPropagation();
  };
  const handleShow = (e) => {
    setShowOffCanvas?.(true);
    setShow(true);
    e?.stopPropagation();
  };
  const { buttonContent, title, body, btnVariant, btnSize, size, showOffCanvas, setShowOffCanvas, disabledBtnTrigger } = props;

  useEffect(() => {
    setShow(showOffCanvas);
  }, [showOffCanvas]);

  const style = { zIndex: 1900, width: "900px" };
  return (
    <>
      <DomainButton size={size} btnSize={btnSize ?? "sm"} variant={btnVariant ?? "primary"} onClick={handleShow} className="me-1" disabled={disabledBtnTrigger}>
        {buttonContent}
      </DomainButton>
      <Offcanvas onClick={(e) => e.stopPropagation()} style={style} show={showOffCanvas ?? show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {body}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}