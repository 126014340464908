import { OffcanvasDomain } from "../../../../_components/Abstractions/OffcanvasDomain";
import { BadgeDomain } from "../../../../_components/Abstractions/BadgeDomain";
import React from "react";
import { stringToDate } from "../../../../domain/Configuration";
import { TableDomain } from "../../../../_components/Abstractions/TableDomain";

export function ContractorRatePlansHistory({ ratePlan }) {
  const { historyItems } = ratePlan;
  const title = "Zmiany w cenniku";
  const btnTitle = <>Zmiany w cenniku <BadgeDomain bg="success">{historyItems?.length ?? 0}</BadgeDomain></>;

  return (
    <>
    <OffcanvasDomain
      btnVariant="outline-primary"
      size="smaller"
      btnSize="sm"
      buttonContent={btnTitle}
      title={title}
      body={<ContractorRatePlansHistoryView historyItems={historyItems}/>}
      />
        </>);
      }

export function ContractorRatePlansHistoryView({ historyItems }) {
  const getChangeDescription = (logItem) => {
    return logItem?.changeEntries?.map((x, idx) => {
      const isPriceChanged = x.changeType === "Price"
        || x.changeType === "PriceBaled"
        || x.changeType === "PriceLoose";
      const priceChangeDetail = x.changeType === "PriceBaled"
        ? "Bel." : x.changeType === "PriceLoose" ? "Luz" : "";
      let pricingType = "";
      if(x.pricingType === "Waste"){
        pricingType = "towaru"
      }
      else if(x.pricingType === "Service") {
        pricingType = "usługi"
      }
      if(!x.oldValue && !x.newValue){
        return <div key={idx}>{x.propertyName}</div>;
      }

      if (x.oldValue && x.newValue) {
        return <div key={idx}>Zmiana {isPriceChanged ? "ceny" : "pola"} {priceChangeDetail} {pricingType} <b>{x.propertyName}</b> z <b>{`${x.oldValue}${isPriceChanged ? " zł" : ""}` ?? "Brak"}</b> na <b>{`${x.newValue}${isPriceChanged ? " zł" : ""}` ?? "Brak"}</b></div>;
      }
      if(!x.newValue) {
        return <div key={idx}>Usunięto {isPriceChanged ? "cenę" : "pole"} {priceChangeDetail} {pricingType} <b>{x.propertyName}</b> - <b>{`${x.oldValue}${isPriceChanged ? " zł" : ""}`}</b></div>;
      }

      return <div key={idx}>Ustawiono {isPriceChanged ? "cenę" : "pole"} {priceChangeDetail} {pricingType} <b>{x.propertyName}</b> na <b>{`${x.newValue}${isPriceChanged ? " zł" : ""}` ?? "Brak"}</b></div>;
    })
      ;
  };

  const columns = [
    { name: "createdAt", headerName: "Data", map: (x) => stringToDate(x.createdAt) },
    { name: "userLogin", headerName: "Użytkownik" },
    { name: "changes", headerName: "Opis", map: (x) => getChangeDescription(x) }
  ];

  return <div className="mt-1">
    {<TableDomain keyColumn="id" bordered columns={columns} items={historyItems} selectable={false}/>}
  </div>;
}