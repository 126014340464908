import { CardView } from "../../../../../_components/Abstractions/CardView";
import { useFormContext } from "react-hook-form";
import { Dropdown } from "../../../../../_components/Abstractions/Dropdown";
import { RatePlanType } from "../../../../../domain/RatePlanType";
import { FormControl } from "../../../../../_components/Forms/FormControl";
import { HorizontalView } from "../../../../../_components/Abstractions/HorizontalView";

export function DefinitionRatePlanForm() {
  const { watch, getValues } = useFormContext();
  const intermediares = getValues("intermediaries");
  const addresses = getValues("addresses");
  const isTypeEditable = getValues("isTypeEditable");
  const isClone = getValues("isClone");
  const isIntermediaryPricing = watch("isIntermediaryPricing");

  return <>
    <CardView headerText="Definicja cennika" headerTypo="h5">
      <Dropdown name="type" label="Typ cennika" items={RatePlanType} readonly={!isTypeEditable}/>
      <Dropdown label="Adres" items={addresses} name="addressId" required={false} readonly={isClone}/>
      <HorizontalView>
        <FormControl name="dateFrom" label="Data początkowa" inputType="date"/>
        <FormControl name="dateTo" label="Data końcowa" inputType="date" required={false}/>
      </HorizontalView>
      {isIntermediaryPricing && <Dropdown label="Pośrednik" items={intermediares} name="intermediaryId"/>  }
    </CardView>
  </>;
}