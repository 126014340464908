import { useFieldArray, useFormContext } from "react-hook-form";
import { CardView } from "../../../../../_components/Abstractions/CardView";
import { HorizontalView } from "../../../../../_components/Abstractions/HorizontalView";
import { NumericControl } from "../../../../../_components/Forms/NumericControl";
import { DomainButton } from "../../../../../_components/Abstractions/DomainButton";
import { ContractorServiceSelect } from "../../../../../_components/ContractorServices/ContractorServiceSelect";
import { DomainSwitch } from "../../../../../_components/Abstractions/DomainSwitch";

export function ServicesRatePlanForm() {
  const { fields, append, remove } = useFieldArray({ name: "services" });
  const { getFieldState } = useFormContext();
  const fieldState = getFieldState("services");
  const GetFieldName = (fieldName, index) => `services[${index}].${fieldName}`;
  const addItem = () => {
    append({
      service: null,
      price: null
    });
  };

  return <>
    <CardView headerText="Usługi" headerTypo="h5">
      <div className="domain-control error mb-2">{fieldState?.error?.message}</div>
      {fields?.map((service, index) => (
        <div key={service.id}>
          {/*<Card.Header>{`${index + 1}. Kontener `}</Card.Header>*/}
          <HorizontalView columnsSize={[7, 3, 2]}>
            <ContractorServiceSelect name={GetFieldName("service", index)} label="Usługa"/>
            <NumericControl name={GetFieldName("price", index)} label="Cena [zł]"/>
            <DomainButton size="large" onClick={() => remove(index)} className="mt-2">Usuń</DomainButton>
          </HorizontalView>
        </div>
      ))}
      <DomainButton onClick={addItem}>Dodaj usługę</DomainButton>
    </CardView>
  </>;
}